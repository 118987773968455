import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject } from 'rxjs';

const COOKIES_ACCEPTED_KEY = '_zoomCar_cookies';

@Injectable({
  providedIn: 'root',
})
export class CookieConfirmService {
  private cookiesShown = new BehaviorSubject<boolean>(false);

  cookiesShown$ = this.cookiesShown.asObservable();

  constructor(private ssrCookieService: SsrCookieService) {}

  cookiesAccepted() {
    if (this.ssrCookieService.check(COOKIES_ACCEPTED_KEY)) {
      return true;
    }
    return false;
  }

  acceptCookies() {
    this.ssrCookieService.set(COOKIES_ACCEPTED_KEY, 'true', 400);
  }
}

import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { CookieConfirmService } from './services/cookie-confirm.service';
import { LangSelectService } from './services/lang-select.service';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { GtmService } from './services/gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieConfirmService: CookieConfirmService,
    private langSelectService: LangSelectService,
    // private gtmService: GoogleTagManagerService,
    private gtmService: GtmService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.langSelectService.setActiveLang(
        this.langSelectService.getInitialLanguage()
      );
      const lang = this.langSelectService.getInitialLanguage();
      if (lang === 'en') {
        this._document.documentElement.lang = 'en-US';
      } else if (lang === 'sr') {
        this._document.documentElement.lang = 'sr-RS';
      }
    }
    if (this.cookieConfirmService.cookiesAccepted()) {
      this.langSelectService.setActiveLang(
        this.langSelectService.getInitialLanguage()
      );
    }
    if (isPlatformBrowser(this.platformId)) {
      this.translate.onLangChange.subscribe((res) => {
        if (res.lang === 'en') {
          this._document.documentElement.lang = 'en-US';
        } else if (res.lang === 'sr') {
          this._document.documentElement.lang = 'sr-RS';
        }
      });
      // this.router.events.forEach((item) => {
      //   if (item instanceof NavigationEnd) {
      //     const gtmTag = {
      //       event: 'page',
      //       pageName: item.url,
      //     };
      //     this.gtmService.pushTag(gtmTag);
      //   }
      // });
    }
  }
  ngAfterViewInit(): void {
    if (
      isPlatformBrowser(this.platformId) &&
      !this.cookieConfirmService.cookiesAccepted()
    ) {
      setTimeout(() => {
        this.confirmationService.confirm({
          message: this.translate.instant('cookies.question'),
          header: this.translate.instant('cookies.title'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.translate.instant('cookies.yes'),
          rejectLabel: this.translate.instant('cookies.no'),
          accept: () => {
            this.cookieConfirmService.acceptCookies();
            this.langSelectService.setInitalLanguage();
            this.messageService.add({
              severity: 'info',
              summary: this.translate.instant('messages.cookiesAcceptedTitle'),
              detail: this.translate.instant('messages.cookiesAcceptedSummary'),
              life: environment.APP_NOTIFICATION_LIFE,
            });
          },
          // reject: (type: ConfirmEventType) => {
          //   switch (type) {
          //     case ConfirmEventType.REJECT:
          //       this.messageService.add({
          //         severity: 'error',
          //         summary: this.translate.instant(
          //           'messages.cookiesRecjectedTitle'
          //         ),
          //         detail: this.translate.instant(
          //           'messages.cookiesRecjectedSummary'
          //         ),
          //         life: environment.APP_NOTIFICATION_LIFE,
          //       });
          //       break;
          //     case ConfirmEventType.CANCEL:
          //       this.messageService.add({
          //         severity: 'warn',
          //         summary: this.translate.instant(
          //           'messages.cookiesCanceledTitle'
          //         ),
          //         detail: this.translate.instant(
          //           'messages.cookiesCanceledSummary'
          //         ),
          //         life: environment.APP_NOTIFICATION_LIFE,
          //       });
          //       break;
          //   }
          // },
        });
      }, 1000);
    }
  }
}

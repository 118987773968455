import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DockModule } from 'primeng/dock';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dock',
  standalone: true,
  imports: [CommonModule, RadioButtonModule, DockModule, FormsModule],
  templateUrl: './dock.component.html',
  styleUrls: ['./dock.component.scss'],
})
export class DockComponent implements OnInit {
  items: MenuItem[] | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  position: 'bottom' | 'top' | 'left' | 'right' = 'right';

  positionOptions = [
    {
      label: 'Bottom',
      value: 'bottom',
    },
    {
      label: 'Top',
      value: 'top',
    },
    {
      label: 'Left',
      value: 'left',
    },
    {
      label: 'Right',
      value: 'right',
    },
  ];

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.items = [
        {
          label: 'Phone',
          icon: './assets/img/dock-phone.svg',
          url: 'tel:+381 66 633 63 36',
        },
        {
          label: 'Viber',
          icon: './assets/img/dock-viber.svg',
          url: 'viber://chat?number=%2B381666336336',
        },
        {
          label: 'Whatssup',
          icon: './assets/img/dock-whatssup.svg',
          url: 'https://wa.me/+381666336336',
        },
        {
          label: 'Email',
          icon: './assets/img/dock-email.svg',
          url: 'mailto:rezervacija@zencar.rs',
        },
      ];
    }
  }
}

<header class="my-10 container px-3 2xl:px-0 flex 2xl:grid 2xl:max-w-full">
  <div class="logo">
    <a [routerLink]="['/']" class="cursor-pointer">
      <svg
        viewBox="0 0 182 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-4 2xl:mx-auto w-24 sm:w-44"
      >
        <title>ZEN CAR</title>
        <path
          d="M93.3744 0C76.1171 0 61.6332 1.849 56.3174 4.23729C51.0015 6.70262 45.0693 18.49 45.0693 18.49C45.0693 18.49 41.4483 17.7966 40.0616 16.5639C38.7519 15.3313 42.1417 12.0185 34.0523 12.0185C25.963 12.0185 25.5778 16.3328 34.0523 18.9522C42.5269 21.5716 42.5269 22.8043 42.5269 22.8043C33.2049 22.8043 29.5839 27.5809 27.812 35.7473C26.04 43.7596 24.3451 55.624 30.4314 61.2481C28.5824 53.3128 32.2804 48.6903 41.3713 52.7735C30.9707 44.6841 29.1987 33.8213 34.8998 32.3575C40.6009 30.8937 60.6317 43.0662 60.6317 43.0662C54.1602 36.3636 42.9121 28.8906 42.9121 27.1186C42.9121 25.3467 50.9244 9.01387 58.0123 6.16333C65.1001 3.31279 93.3744 2.77349 93.3744 2.77349V0Z"
          fill="url(#paint0_linear_1_9)"
        />
        <path
          d="M93.3744 0C110.632 0 125.116 1.849 130.431 4.23729C135.747 6.70262 141.68 18.49 141.68 18.49C141.68 18.49 145.3 17.7966 146.687 16.5639C147.997 15.3313 144.607 12.0185 152.696 12.0185C160.786 12.0185 161.171 16.3328 152.696 18.9522C144.222 21.5716 144.222 22.8043 144.222 22.8043C153.544 22.8043 157.165 27.5809 158.937 35.7473C160.709 43.7596 162.404 55.624 156.317 61.2481C158.166 53.3128 154.468 48.6903 145.377 52.7735C155.778 44.6841 157.55 33.8213 151.849 32.3575C146.148 30.8937 126.117 43.0662 126.117 43.0662C132.512 36.2866 143.76 28.8136 143.76 27.0416C143.76 25.2696 135.747 8.93682 128.659 6.08628C121.572 3.23574 93.2974 2.69646 93.2974 2.69646V0H93.3744Z"
          fill="url(#paint1_linear_1_9)"
        />
        <path
          d="M138.059 14.9461C141.834 21.2635 93.4515 21.0324 93.4515 21.0324C93.4515 21.0324 44.9924 21.2635 48.8444 14.9461L46.6102 19.2604C48.6904 21.1864 76.2712 21.9569 93.5286 21.9569C110.786 21.9569 138.367 21.1864 140.447 19.2604L138.059 14.9461Z"
          fill="url(#paint2_linear_1_9)"
        />
        <path
          d="M121.263 43.7596C114.176 45.6857 93.3744 45.4545 93.3744 45.4545C93.3744 45.4545 72.5732 45.6857 65.4854 43.7596C70.416 46.1479 80.3544 47.6117 93.3744 47.6117C106.394 47.6117 116.333 46.1479 121.263 43.7596Z"
          fill="url(#paint3_linear_1_9)"
        />
        <path
          d="M122.573 51.3867C126.117 57.1649 123.344 61.094 114.022 61.7874C105.162 62.4037 95.6087 62.4037 94.6071 62.4037C93.3744 62.4037 81.7411 62.4037 72.8814 61.7874C63.5593 61.171 60.7858 57.1649 64.3298 51.3867C56.7026 55.547 49.5378 55.3929 44.9923 53.3128C46.1479 55.7011 52.6194 60.3236 58.7057 62.4807C64.6379 64.6379 78.2743 64.792 94.5301 64.792H94.6071C94.6842 64.792 94.6841 64.792 94.7612 64.792C110.94 64.792 122.342 64.5609 128.274 62.4807C134.361 60.2465 140.832 55.7011 141.988 53.3128C137.365 55.3929 130.123 55.547 122.573 51.3867Z"
          fill="url(#paint4_linear_1_9)"
        />
        <path
          d="M7.01078 93.9907H24.4222V99.923H1.46379C1.23266 99.923 1.07858 99.7689 0.847451 99.3837C0.616326 99.0755 0.38521 98.5362 0.231127 97.9969C0.077044 97.3806 0 96.7642 0 95.9938C0 95.2234 0.154083 94.453 0.385208 93.6055C0.693374 92.7581 1.15563 91.9106 1.77196 91.0632C2.38829 90.2157 3.23574 89.4453 4.39137 88.6749L19.7226 79.8921H0.924497V73.8829H22.9584C23.3436 73.8829 23.7288 74.114 24.114 74.4992C24.4992 74.8844 24.8074 75.5008 25.0385 76.1942C25.2696 76.8875 25.3467 77.6579 25.4237 78.5054C25.4237 79.3529 25.3467 80.2774 25.0385 81.2019C24.7304 82.1264 24.2681 82.9738 23.5747 83.8983C22.8813 84.7458 21.9569 85.5162 20.8012 86.2096L7.01078 93.9907Z"
          fill="url(#paint5_linear_1_9)"
        />
        <path
          d="M27.3497 82.7427C27.3497 81.6641 27.5809 80.5085 27.9661 79.5069C28.3513 78.4283 28.9676 77.5038 29.738 76.6564C30.5084 75.8089 31.51 75.1926 32.6656 74.6533C33.8212 74.114 35.1309 73.8829 36.5947 73.8829H52.3112V79.8921H36.9799C36.3636 79.8921 35.9013 79.9692 35.4391 80.2003C34.9769 80.4314 34.5916 80.7396 34.2835 81.1248C33.9753 81.51 33.7442 81.8952 33.5901 82.4345C33.436 82.8968 33.359 83.436 33.359 83.9753H52.3883V89.9846H33.359C33.359 90.9861 33.6671 91.9106 34.3605 92.681C34.9768 93.4515 35.9013 93.8367 37.057 93.8367H52.3883V99.923H36.6718C35.1309 99.923 33.8212 99.6918 32.6656 99.1525C31.51 98.6132 30.5084 97.9199 29.738 97.0724C28.9676 96.225 28.3513 95.3005 27.9661 94.2989C27.5809 93.2974 27.3497 92.2188 27.3497 91.2943V82.7427Z"
          fill="url(#paint6_linear_1_9)"
        />
        <path
          d="M76.1941 99.923C75.5778 99.923 75.0385 99.7689 74.4992 99.5378C73.9599 99.2296 73.4206 98.9214 72.8813 98.5362C72.342 98.151 71.8798 97.6117 71.4946 97.1495C71.1094 96.6872 70.6471 96.0709 70.339 95.6086L61.6333 82.5886V99.923H55.624V73.8059H60.1695C60.6317 73.8059 61.094 73.8829 61.5562 74.114C62.0185 74.2681 62.5578 74.5763 63.02 74.8844C63.4823 75.1926 63.9445 75.5778 64.4068 75.963C64.869 76.4253 65.2542 76.8875 65.5624 77.3498L75.5008 92.527V73.8059H81.587V99.923H76.1941Z"
          fill="url(#paint7_linear_1_9)"
        />
        <path
          d="M97.6117 82.7427C97.6117 81.6641 97.8428 80.5085 98.228 79.5069C98.6132 78.4283 99.2296 77.5038 100 76.6564C100.77 75.8089 101.772 75.1926 102.928 74.6533C104.083 74.114 105.393 73.8829 106.857 73.8829H122.573V79.8921H107.242C106.626 79.8921 106.163 79.9692 105.701 80.2003C105.239 80.4314 104.854 80.7396 104.545 81.1248C104.237 81.51 104.006 81.8952 103.852 82.4345C103.698 82.8968 103.621 83.436 103.621 83.9753V86.8259V89.9846C103.621 90.9861 103.929 91.9106 104.623 92.681C105.239 93.4515 106.163 93.8367 107.319 93.8367H122.65V99.923H106.857C105.316 99.923 104.006 99.6918 102.851 99.1525C101.695 98.6132 100.693 97.9199 99.923 97.0724C99.1525 96.225 98.5362 95.3005 98.151 94.2989C97.7658 93.2974 97.5347 92.2188 97.5347 91.2943V86.9029V82.7427H97.6117Z"
          fill="url(#paint8_linear_1_9)"
        />
        <path
          d="M148.921 73.8059H152.157V99.923H146.148V95.4546H132.126C131.587 96.9954 131.202 98.5362 131.048 99.923H124.807C124.961 97.8428 125.424 95.7627 126.117 93.6826C126.81 91.6025 127.735 89.5223 128.814 87.5963C129.892 85.6703 131.202 83.8213 132.666 82.2034C134.129 80.5085 135.747 79.0447 137.442 77.812C139.137 76.5793 140.986 75.5778 142.989 74.8844C144.838 74.1911 146.841 73.8059 148.921 73.8059ZM146.071 79.8921C144.915 80.1233 143.76 80.5855 142.681 81.2019C141.602 81.8182 140.601 82.5116 139.676 83.359C138.752 84.2065 137.827 85.131 137.057 86.2096C136.21 87.2881 135.516 88.3667 134.823 89.4453H146.071V79.8921Z"
          fill="url(#paint9_linear_1_9)"
        />
        <path
          d="M174.807 99.923L170.262 90.2157H161.325V99.923H155.316V84.0524H172.958C173.421 84.0524 173.806 83.9753 174.114 83.8213C174.422 83.6672 174.653 83.5131 174.884 83.359C175.039 83.1279 175.193 82.9738 175.27 82.7427C175.347 82.5116 175.347 82.2804 175.347 82.0493C175.347 81.8182 175.347 81.587 175.27 81.3559C175.193 81.1248 175.039 80.8937 174.884 80.6626C174.73 80.4314 174.422 80.2773 174.114 80.1233C173.806 79.9692 173.421 79.8921 172.958 79.8921H155.316V73.8829H172.958C174.191 73.8829 175.424 74.114 176.502 74.5763C177.581 75.0385 178.505 75.6549 179.276 76.4253C179.969 77.2727 180.509 78.1972 180.894 79.1217C181.279 80.0462 181.433 81.0478 181.433 82.1263C181.433 83.0508 181.279 84.0524 180.894 84.9769C180.509 85.9784 179.969 86.9029 179.276 87.7504C178.968 88.1356 178.582 88.5208 178.12 88.7519C177.658 88.983 177.273 89.2912 176.811 89.5223L181.741 100H174.807V99.923Z"
          fill="url(#paint10_linear_1_9)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_9"
            x1="61.557"
            y1="-2.32442"
            x2="55.779"
            y2="62.6777"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#35A0FF" />
            <stop offset="1" stop-color="#390096" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_9"
            x1="128.896"
            y1="3.66117"
            x2="123.118"
            y2="68.6636"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#35A0FF" />
            <stop offset="1" stop-color="#390096" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_9"
            x1="95.039"
            y1="0.651683"
            x2="89.261"
            y2="65.6537"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#35A0FF" />
            <stop offset="1" stop-color="#390096" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1_9"
            x1="97.1957"
            y1="0.843603"
            x2="91.4178"
            y2="65.8456"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#35A0FF" />
            <stop offset="1" stop-color="#390096" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1_9"
            x1="98.0368"
            y1="0.918431"
            x2="92.2588"
            y2="65.9204"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#35A0FF" />
            <stop offset="1" stop-color="#390096" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1_9"
            x1="-0.105162"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1_9"
            x1="-0.1052"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1_9"
            x1="-0.105172"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1_9"
            x1="-0.105185"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1_9"
            x1="-0.105193"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1_9"
            x1="-0.105207"
            y1="86.8507"
            x2="181.706"
            y2="86.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E0E0E0" />
            <stop offset="0.508" stop-color="#3D3E3F" />
            <stop offset="1" stop-color="#EBEDEC" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  </div>
  <div class="nav w-full">
    <div class="upper-nav flex justify-end xl:justify-between">
      <ul
        class="text-white font-semibold text-sm hidden xl:flex gap-8 items-center"
      >
        <li>
          <a
            class="flex items-center gap-2 group hover:text-doger-blue focus:text-doger-blue transition-colors"
            href="mailto:rezervacija@zencar.rs"
            ><svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-hover:fill-doger-blue group-focus:fill-doger-blue transition-colors"
                d="M16 3.373V10.5C16 11.163 15.7366 11.7989 15.2678 12.2678C14.7989 12.7366 14.163 13 13.5 13H2.5C1.83696 13 1.20107 12.7366 0.732233 12.2678C0.263392 11.7989 0 11.163 0 10.5V3.373L7.746 7.931C7.82295 7.97638 7.91066 8.00032 8 8.00032C8.08934 8.00032 8.17705 7.97638 8.254 7.931L16 3.373ZM13.5 3.09527e-08C14.1151 -9.6716e-05 14.7087 0.226607 15.1672 0.63674C15.6257 1.04687 15.9168 1.61164 15.985 2.223L8 6.92L0.0150001 2.223C0.0831583 1.61164 0.374324 1.04687 0.832795 0.63674C1.29127 0.226607 1.88485 -9.6716e-05 2.5 3.09527e-08H13.5Z"
                fill="white"
              />
            </svg>
            rezervacija&#64;zencar.rs
          </a>
        </li>
        <li>
          <a
            class="flex items-center gap-2 group hover:text-doger-blue focus:text-doger-blue transition-colors"
            href="tel:+381 66 633 63 36"
          >
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-hover:fill-doger-blue group-focus:fill-doger-blue transition-colors"
                d="M9.60238 8.33993L9.25444 8.68635C9.25444 8.68635 8.42625 9.50918 6.16653 7.26245C3.9068 5.01573 4.73499 4.1929 4.73499 4.1929L4.95369 3.97419C5.49435 3.43736 5.54558 2.57477 5.07375 1.94465L4.11022 0.657637C3.52598 -0.12237 2.39803 -0.225606 1.7289 0.439694L0.528306 1.63264C0.197186 1.963 -0.0245809 2.38971 0.00218402 2.86383C0.0710081 4.07743 0.620071 6.68739 3.68198 9.73248C6.92971 12.9611 9.97709 13.0896 11.2228 12.9733C11.6174 12.9366 11.96 12.7363 12.236 12.461L13.3219 11.3812C14.0561 10.6524 13.8496 9.40212 12.9105 8.89206L11.4499 8.09752C10.8336 7.76258 10.0841 7.86122 9.60238 8.33993Z"
                fill="white"
              />
            </svg>
            +381 66 633 63 36
          </a>
        </li>
      </ul>
      <ul class="flex gap-8 items-center">
        <!-- <li class="hidden xl:block">
          <button
            class="text-white font-semibold text-sm flex items-center gap-2 group uppercase hover:text-doger-blue focus:text-doger-blue transition-colors"
            type="button"
          >
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="group-hover:fill-doger-blue group-focus:fill-doger-blue transition-colors"
                d="M0 13C0 11.6865 0.521767 10.4269 1.45052 9.49814C2.37927 8.56939 3.63893 8.04762 4.95238 8.04762C6.26583 8.04762 7.52549 8.56939 8.45424 9.49814C9.383 10.4269 9.90476 11.6865 9.90476 13H0ZM4.95238 7.42857C2.90024 7.42857 1.2381 5.76643 1.2381 3.71429C1.2381 1.66214 2.90024 0 4.95238 0C7.00452 0 8.66667 1.66214 8.66667 3.71429C8.66667 5.76643 7.00452 7.42857 4.95238 7.42857Z"
                fill="white"
              />
            </svg>
            {{ 'header.login.login' | translate }}
          </button>
        </li> -->
        <li class="lang-select" *ngIf="countries">
          <p-dropdown
            [options]="countries"
            [(ngModel)]="selectedCountry"
            optionLabel="name"
            [showClear]="true"
            placeholder=""
            [showClear]="false"
            (ngModelChange)="onChange($event)"
            [dropdownIcon]=""
            styleClass="bg-transparent"
          >
            <ng-template pTemplate="selectedItem">
              <div
                class="flex align-items-center gap-2 bg-transparent"
                *ngIf="selectedCountry"
              >
                <img [src]="selectedCountry.img_url" style="width: 24px" />
                <div class="text-sm text-white font-semibold">
                  {{ selectedCountry.name }}
                </div>
                <button>
                  <svg
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.330314 1.90516L3.21591 4.79076C3.31898 4.89404 3.44141 4.97599 3.57619 5.03189C3.71097 5.0878 3.85546 5.11658 4.00137 5.11658C4.14729 5.11658 4.29177 5.0878 4.42655 5.03189C4.56133 4.97599 4.68376 4.89404 4.78684 4.79076L7.67243 1.90516C8.37434 1.20326 7.87298 0 6.8814 0H1.11021C0.118629 0 -0.371588 1.20326 0.330314 1.90516Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item" class="bg-white">
              <div class="flex align-items-center gap-2">
                <img [src]="country.img_url" style="width: 24px" />
                <div class="text-sm text-paynes-gray font-semibold">
                  {{ country.name }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </li>
        <li class="block xl:hidden">
          <button type="button" (click)="openMenu()">
            <svg
              width="43"
              height="31"
              viewBox="0 0 43 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3H40.5M3 15.5H40.5M3 28H40.5"
                stroke="white"
                stroke-width="5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
    <nav
      class="fixed z-50 inset-0 xl:relative overflow-auto xl:pb-0 pb-7 bg-black xl:bg-transparent opacity-[0.95] xl:opacity-1 transition-transform xl:translate-x-0"
      [ngClass]="menuClose"
    >
      <ul
        class="text-sm text-white font-semibold uppercase py-4 flex gap-8 flex-col xl:flex-row xl:justify-end transition-colors"
      >
        <button
          class="self-end mt-[78px] mr-[36px] block xl:hidden"
          (click)="closeMenu()"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.559099 0.559099C0.917199 0.201108 1.40282 0 1.90917 0C2.41553 0 2.90115 0.201108 3.25925 0.559099L10.0115 7.31138L16.7638 0.559099C17.124 0.211254 17.6063 0.0187788 18.107 0.0231296C18.6077 0.0274804 19.0866 0.228309 19.4407 0.582361C19.7948 0.936413 19.9956 1.41536 19.9999 1.91605C20.0043 2.41673 19.8118 2.8991 19.464 3.25925L12.7117 10.0115L19.464 16.7638C19.8118 17.124 20.0043 17.6063 19.9999 18.107C19.9956 18.6077 19.7948 19.0866 19.4407 19.4407C19.0866 19.7948 18.6077 19.9956 18.107 19.9999C17.6063 20.0043 17.124 19.8118 16.7638 19.464L10.0115 12.7117L3.25925 19.464C2.8991 19.8118 2.41673 20.0043 1.91605 19.9999C1.41536 19.9956 0.936413 19.7948 0.582361 19.4407C0.228309 19.0866 0.0274804 18.6077 0.0231296 18.107C0.0187788 17.6063 0.211254 17.124 0.559099 16.7638L7.31138 10.0115L0.559099 3.25925C0.201108 2.90115 0 2.41553 0 1.90917C0 1.40282 0.201108 0.917199 0.559099 0.559099Z"
              fill="#fff"
            />
          </svg>
        </button>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['/']"
            [routerLinkActiveOptions]="{ exact: true }"
            >{{ 'header.menu.link1' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug2' | translate]"
            >{{ 'header.menu.link2' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug3' | translate]"
            >{{ 'header.menu.link3' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug7' | translate]"
            >{{ 'header.menu.link7' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug4' | translate]"
            >{{ 'header.menu.link4' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug5' | translate]"
            >{{ 'header.menu.link5' | translate }}</a
          >
        </li>
        <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['header.menu.slug6' | translate]"
            >{{ 'header.menu.link6' | translate }}</a
          >
        </li>
        <!-- <li>
          <a
            (click)="closeMenu()"
            class="block text-center hover:text-doger-blue focus:text-doger-blue transition-colors"
            [routerLinkActive]="['text-doger-blue']"
            [routerLink]="['neki-testni-page']"
            >testni link</a
          >
        </li> -->
        <!-- <li
          (click)="closeMenu()"
          class="flex justify-center xl:pt-0 pt-8 xl:mx-0 mx-[15px] xl:hidden xl:border-none border-t-2 border-white"
        >
          <button
            class="text-white font-semibold text-sm flex items-center gap-2 uppercase"
            type="button"
          >
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13C0 11.6865 0.521767 10.4269 1.45052 9.49814C2.37927 8.56939 3.63893 8.04762 4.95238 8.04762C6.26583 8.04762 7.52549 8.56939 8.45424 9.49814C9.383 10.4269 9.90476 11.6865 9.90476 13H0ZM4.95238 7.42857C2.90024 7.42857 1.2381 5.76643 1.2381 3.71429C1.2381 1.66214 2.90024 0 4.95238 0C7.00452 0 8.66667 1.66214 8.66667 3.71429C8.66667 5.76643 7.00452 7.42857 4.95238 7.42857Z"
                fill="white"
              />
            </svg>
            {{ 'header.login.login' | translate }}
          </button>
        </li> -->
      </ul>
    </nav>
  </div>
</header>

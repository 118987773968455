export const environment = {
  production: false,

  API_ZEN: 'https://staging-api.rentacarzen.com/api/v1',

  API_GET_ALL_CATEGORYS: '/frontend/categories',
  API_GET_ALL_LOCATIONS: '/frontend/locations',
  API_GET_SEARCHED_CARS: '/frontend/cars',
  API_POST_CREATE_RESERVATION: '/frontend/reservations/create',
  API_PATCH_UPDATE_RESERVATION: '/frontend/reservations/update',
  API_DELETE_DESTROY_RESERVATION: '/frontend/reservations/destroy',
  API_POST_CONTACT: '/frontend/contact-form',

  APP_PORT: '4000',
  APP_SERVER_DIST_FOLDER: '../browser',
  APP_SERVER_TRANSLATE_FOLDER: '../',
  APP_NOTIFICATION_LIFE: 7000,
  APP_RECAPTCHA_KEY: '6LeShDwpAAAAAOrRXOYxvkJqxYv-G9k9lk7szKnP',
  APP_RECAPTCHA_WILDCARD: 'd8d685cd-fe9a-485d-a75d-9158b02f6e43-fd723od8',
  APP_GOOGLE_TAG_KEY: 'G-LR44E5QE6P',
};

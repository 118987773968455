import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';

const LANG_COOKIE = '_zoomCar_lang';
const DEFAULT_LANG = 'sr';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(private ssrCookieService: SsrCookieService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.ssrCookieService.check(LANG_COOKIE)) {
      const lang = this.ssrCookieService.get(LANG_COOKIE);
      const cloned = request.clone({
        headers: request.headers.set('X-App-Locale', `${lang}`),
      });
      return next.handle(cloned);
    } else {
      const cloned3 = request.clone({
        headers: request.headers.set('X-App-Locale', DEFAULT_LANG),
      });
      return next.handle(cloned3);
    }
  }
}

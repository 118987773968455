import { NgModule, TransferState } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './loaders/translate-browser.loader';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { DockComponent } from './components/dock/dock.component';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderComponent,
    HttpClientModule,
    FooterComponent,
    DockComponent,
    ConfirmDialogModule,
    ToastModule,
    RecaptchaV3Module,
    // GoogleTagManagerModule.forRoot({
    //   id: environment.APP_GOOGLE_TAG_KEY,
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: 'sr',
    }),
  ],
  providers: [
    // { provide: 'googleTagManagerId', useValue: environment.APP_GOOGLE_TAG_KEY },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.APP_RECAPTCHA_KEY },
    provideClientHydration(),
    SsrCookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    ConfirmationService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

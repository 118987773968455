import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'search',
    loadChildren: () =>
      import('./pages/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'car-single/:id',
    loadChildren: () =>
      import('./pages/car-single/car-single.module').then(
        (m) => m.CarSingleModule
      ),
  },
  {
    path: 'nasa-flota',
    loadChildren: () =>
      import('./pages/fleet/fleet.module').then((m) => m.FleetModule),
  },
  {
    path: 'out-vehicle-fleet',
    loadChildren: () =>
      import('./pages/fleet/fleet.module').then((m) => m.FleetModule),
  },
  {
    path: 'limo-service',
    loadChildren: () =>
      import('./pages/luxury-limo-service/luxury-limo-service.module').then(
        (m) => m.LuxuryLimoServiceModule
      ),
  },
  {
    path: 'limo-servis',
    loadChildren: () =>
      import('./pages/luxury-limo-service/luxury-limo-service.module').then(
        (m) => m.LuxuryLimoServiceModule
      ),
  },
  {
    path: 'operativni-lizing',
    loadChildren: () =>
      import('./pages/operating-leasing/operating-leasing.module').then(
        (m) => m.OperatingLeasingModule
      ),
  },
  {
    path: 'logn-term-rental',
    loadChildren: () =>
      import(
        './pages/long-term-vehicle-rental/long-term-vehicle-rental.module'
      ).then((m) => m.LongTermVehicleRentalModule),
  },
  {
    path: 'dugorocni-najam',
    loadChildren: () =>
      import(
        './pages/long-term-vehicle-rental/long-term-vehicle-rental.module'
      ).then((m) => m.LongTermVehicleRentalModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./pages/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  // {
  //   path: 'neki-testni-page',
  //   loadChildren: () =>
  //     import('./pages/contact-us/contact-us.module').then(
  //       (m) => m.ContactUsModule
  //     ),
  // },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'o-nama',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'benefits',
    loadChildren: () =>
      import('./pages/benefits/benefits.module').then((m) => m.BenefitsModule),
  },
  {
    path: 'user-details',
    loadChildren: () =>
      import('./pages/user-details/user-details.module').then(
        (m) => m.UserDetailsModule
      ),
  },
  {
    path: 'user-service',
    loadChildren: () =>
      import('./pages/user-service/user-service.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'terms-of-lease',
    loadChildren: () =>
      import('./pages/terms-of-lease/terms-of-lease.module').then(
        (m) => m.TermsOfLeaseModule
      ),
  },
  {
    path: 'operating-leasing',
    loadChildren: () =>
      import('./pages/operating-leasing/operating-leasing.module').then(
        (m) => m.OperatingLeasingModule
      ),
  },
  {
    path: 'successful-booking',
    loadChildren: () =>
      import('./pages/successful-booking/successful-booking.module').then(
        (m) => m.SuccessfulBookingModule
      ),
  },

  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

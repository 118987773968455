import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.pushTag({
          event: 'page',
          pageName: event.urlAfterRedirects,
        });
      });
  }

  pushTag(tag: any) {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(tag);
      // console.log(tag, window.dataLayer);
    }
  }
}

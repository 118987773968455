import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Lang } from '../interfaces/lang/lang.model';
import { CookieConfirmService } from './cookie-confirm.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

const LANG_COOKIE = '_zoomCar_lang';
const DEFAULT_LANG = 'sr';
@Injectable({
  providedIn: 'root',
})
export class LangSelectService {
  private languages: Lang[] = [
    {
      code: 'en',
      name: 'EN',
      img_url: './assets/img/en-flag.svg',
    },
    {
      code: 'sr',
      name: 'SR',
      img_url: './assets/img/sr-flag.svg',
    },
    // {
    //   code: 'de',
    //   name: 'DE',
    //   img_url: './assets/img/de-flag.svg',
    // },
  ];
  constructor(
    private cookieConfirmService: CookieConfirmService,
    private ssrCookieService: SsrCookieService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  getLanguages() {
    return this.languages;
  }

  setInitalLanguage() {
    if (this.cookieConfirmService.cookiesAccepted()) {
      if (this.ssrCookieService.check(LANG_COOKIE)) {
        this.translate.use(this.ssrCookieService.get(LANG_COOKIE));
      } else {
        this.ssrCookieService.set(LANG_COOKIE, DEFAULT_LANG, 400);
        localStorage.setItem(LANG_COOKIE, DEFAULT_LANG);
        this.translate.use(DEFAULT_LANG);
      }
    } else {
      this.translate.use(DEFAULT_LANG);
    }
  }

  getInitialLanguage() {
    if (this.ssrCookieService.check(LANG_COOKIE)) {
      return this.ssrCookieService.get(LANG_COOKIE);
    }
    return DEFAULT_LANG;
  }

  setActiveLang(ln: string) {
    if (this.cookieConfirmService.cookiesAccepted()) {
      this.ssrCookieService.set(LANG_COOKIE, ln, 400, '/');
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(LANG_COOKIE, ln);
      }
    }
    this.translate.use(ln);
    // if (ln !== this.ssrCookieService.get(LANG_COOKIE)) {
    //   // this.setLanguageRemote(this.setLangId(ln));
    // }
  }
}

import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { Lang } from 'src/app/interfaces/lang/lang.model';
import { LangSelectService } from 'src/app/services/lang-select.service';

const LANG_COOKIE = '_zoomCar_lang';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  countries: Lang[] | undefined;

  menuClose = '-translate-x-full';

  selectedCountry: Lang | undefined;

  funcListener: any;
  constructor(
    private langSelectService: LangSelectService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.countries = this.langSelectService.getLanguages();
      this.selectedCountry = this.countries.filter((lang) => {
        return lang.code === this.langSelectService.getInitialLanguage();
      })[0];

      this.funcListener = this.func.bind(this);
      window.addEventListener('storage', this.funcListener);
    }
  }

  func(event: StorageEvent): void {
    if (event.key == LANG_COOKIE) {
      if (event.newValue !== event.oldValue) {
        this.selectedCountry = this.countries?.filter((lang) => {
          return lang.code === event.newValue;
        })[0];
        if (this.selectedCountry) {
          this.langSelectService.setActiveLang(this.selectedCountry.code);
        }
      }
    }
  }

  openMenu() {
    this.menuClose = '';
  }
  closeMenu() {
    this.menuClose = '-translate-x-full';
  }
  onChange(event: Lang) {
    this.langSelectService.setActiveLang(event.code);
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('storage', this.funcListener);
    }
  }
}
